<template>
  <div class="content" v-loading.fullscreen.lock="loading">
    <base-header class="pb-6">
      <div class="row align-items-center py-2">
        <div class="col-lg-6 col-7"></div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <card>
        <!-- Card header -->
        <h2 slot="header" class="mb-0" v-if="badge_id">
          Edit New Badge
        </h2>
        <h2 slot="header" class="mb-0" v-else>
          Add New Badge
        </h2>
        <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(saveBadge)"
            enctype="multipart/form-data"
          >
            <div class="form-row">
              <div class="col-md-6 ">
                <base-input
                  label="Badge Name"
                  name="Badge Name"
                  placeholder="Badge Name"
                  rules="required"
                  v-model="badge_name"
                >
                </base-input>
              </div>
            </div>
            <br />

            <div class="text-right mt-3">
              <base-button
                class="custom-btn"
                type="danger"
                @click="$router.go(-1)"
                >Cancel</base-button
              >
              <base-button class="custom-btn" native-type="submit">{{
                badge_id !== "" ? " Update" : "Submit"
              }}</base-button>
            </div>
          </form>
        </validation-observer>
      </card>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      hot_user: "",
      hot_token: "",
      config: "",
      badge_id: "",
      badge_name: "",
      loading: ""
    };
  },
  created() {
    if (localStorage.getItem("hot-token")) {
      this.hot_user = localStorage.getItem("hot-user");
      this.hot_token = localStorage.getItem("hot-token");
    }

    this.config = {
      headers: {
        authorization: "Bearer " + localStorage.getItem("hot-token"),
        "content-type": "application/json"
      }
    };

    if (this.$route.query.id) {
      this.badge_id = this.$route.query.id;
      this.$http
        .get("course/badge/" + this.badge_id, this.config)
        .then(resp => {
          let data = resp.data;
          this.badge_name = data.name;
        });
    }
  },
  methods: {
    saveBadge() {
      if (this.badge_id !== "") {
        this.$http
          .put(
            "course/badge/" + this.badge_id,
            {
              badge_id: this.badge_id,
              badge_name: this.badge_name
            },
            this.config
          )
          .then(resp => {
            Swal.fire({
              title: "Success!",
              text: resp.data.message,
              icon: "success"
            });
            this.$router.push("/badges");
          })
          .catch(function(error) {
            if (error.response.status === 422) {
              return Swal.fire({
                title: "Error!",
                text: error.response.data.message,
                icon: "error"
              });
            }
          });
      } else {
        this.$http
          .post(
            "course/badge",
            {
              badge_name: this.badge_name
            },
            this.config
          )
          .then(resp => {
            Swal.fire({
              title: "Success!",
              text: `Badge Has been Added!`,
              icon: "success"
            });
            this.$router.push("/badges");
          })
          .catch(function(error) {
            if (error.response.status === 422) {
              return Swal.fire({
                title: "Error!",
                text: error.response.data.message,
                icon: "error"
              });
            }
          });
      }
    }
  }
};
</script>
<style></style>
